<template>
  <div v-loading="loading">
    <div v-if="roles.length > 0">
      <el-button class="ruge-singleBtn-s" size="mini" @click="fullChoice()">
        {{ $t("commons.fullChoice") }}
      </el-button>
      <el-button class="ruge-singleBtn-s" size="mini" @click="unFullChoice()">
        {{ $t("commons.unFullChoice") }}
      </el-button>
      <el-button
        class="ruge-singleBtn-s"
        type="primary"
        size="mini"
        @click="grantRole()"
      >
        {{ $t("commons.save") }}
      </el-button>
    </div>
    <el-row>
      <template v-if="roles.length > 0">
        <el-col
          class="ruge-cards"
          :span="5"
          v-for="(role, index) in roles"
          :key="role.roleId"
          :offset="index % 4 == 0 ? 0 : 1"
        >
          <el-card :class="role.choice" @click.native="choiceCard(role)">
            <div :style="elCardIcon" slot="header">
              <span>{{ role.roleName }}</span>
            </div>
            <div>
              <span class="ruge-card-text">{{ role.roleNameDesc }}</span>
              <div class="bottom" @click.stop>
                <el-date-picker
                  size="mini"
                  style="width: 100%"
                  :disabled="role.disabled"
                  v-model="role.dateRange"
                  :unlink-panels="true"
                  type="daterange"
                  @change="setDateRange(role)"
                  :start-placeholder="$t('commons.startDate')"
                  :end-placeholder="$t('commons.endDate')"
                >
                </el-date-picker>
              </div>
            </div>
            <img class="elCardActive" :src="elCardActiveSrc" />
          </el-card>
        </el-col>
      </template>
      <template v-else>
        <div class="no-data">{{ $t("commons.noData") }}</div>
      </template>
    </el-row>
  </div>
</template>


<style rel="stylesheet/scss" lang="scss" scoped>
.bottom {
  margin-top: 13px;
  line-height: 12px;
}
</style>

<script>
let manager = require("@/api/ruge/security/role");
import { dateFormat } from "@/filters/index";

export default {
  data() {
    return {
      loading: true,
      roles: [],
      elCardIcon: {
        background:
          "url(" +
          require("@/assets/images/el-card-icon.png") +
          ") center right no-repeat",
      },
      elCardActiveSrc: require("@/assets/images/el-card-active.png"),
    };
  },
  props: {
    currentRow: {
      type: Object,
      required: true,
    },
  },
  computed: {
    userAccount() {
      return this.currentRow.userAccount;
    },
  },
  watch: {
    userAccount: function () {
      this.getAllRole();
    },
  },
  created() {
    this.getAllRole();
  },
  methods: {
    getAllRole() {
      this.loading = true;
      manager
        ._tenant_getRoleListByTenant(this.currentRow)
        .then((response) => {
          let hasRole = new Map();
          if (response) {
            response.map((m) => {
              hasRole.set(m.roleId, m);
            });
          }
          manager
            ._tenant_getRoleList({ tenantId: this.currentRow.tenantId })
            .then((resp) => {
              let currentDate = new Date();
              this.roles = resp.map((r) => {
                r.roleNameDesc = r.roleNameDesc
                  ? r.roleNameDesc.length > 15
                    ? r.roleNameDesc.substring(0, 15) + "..."
                    : r.roleNameDesc
                  : r.roleName;
                if (r.roleId == -2) {
                  r.disabled = true;
                } else {
                  r.disabled = false;
                }
                let cr = hasRole.get(r.roleId);
                if (cr) {
                  r.startDate = cr.startDate;
                  r.endDate = cr.endDate;
                  r.choice = "choice";
                } else {
                  r.choice = "";
                  r.startDate = currentDate;
                  r.endDate = new Date().setFullYear(
                    currentDate.getFullYear() + 1
                  );
                }
                r.dateRange = [r.startDate, r.endDate];
                return r;
              });
            })
            .catch(() => {});
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setDateRange(role) {
      role.startDate = role.dateRange[0].getTime();
      role.endDate = role.dateRange[1].getTime();
    },
    choiceCard(role) {
      if (role.roleId == -2) {
        return;
      }
      if (role.choice) {
        this.$set(role, "choice", "");
      } else {
        this.$set(role, "choice", "choice");
      }
    },
    //权限全选
    fullChoice() {
      this.roles.map((m) => {
        if (m.roleId != -2) {
          this.$set(m, "choice", "choice");
        }
      });
    },
    //取消权限全选
    unFullChoice() {
      this.roles.map((m) => {
        if (m.roleId != -2) {
          this.$set(m, "choice", "");
        }
      });
    },
    grantRole() {
      let temp = this.roles.filter((m) => {
        return m.choice;
      });
      let roles = new Array();
      temp.map((m) => {
        if (!m.startDate || !m.endDate) {
          this.$message({
            type: "error",
            message: this.$t("permission.validMessage.dateNull"),
          });
          roles = new Array();
          return;
        }
        roles.push({
          roleId: m.roleId,
          startDate: dateFormat(m.startDate),
          endDate: dateFormat(m.endDate),
        });
      });
      if (temp.length == 0 || roles.length > 0) {
        manager
          ._tenant_grantRole(
            Object.assign({}, this.currentRow, { roleList: roles })
          )
          .then(() => {
            this.$message({
              type: "success",
              message: this.$t("message.saveSuccess"),
            });
          })
          .catch((e) => {
            console.log(`保存失败，原因 => ${e}`);
          });
      }
    },
  },
};
</script>
